import { createContext, useContext } from 'react';

type TLoading = { loading: boolean; setLoading: (x: boolean) => void };

export const Loading = createContext<TLoading>({
  loading: false,
  setLoading: (x: boolean) => {}
});

export const useLoading = () => useContext(Loading);
