import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IUserTypesResponse } from 'utils/models';
import storage from 'utils/storage';
import api from 'utils/api';

interface IProfile {
  id: string;
  email: string;
  is_super_admin: boolean;
  user_type: IUserTypesResponse | null;
}

interface IContext {
  user: IProfile | null;
  isLoading: boolean;
  setIsLoading: (x: boolean) => void;
  login: (email: string, password: string) => Promise<any> | void;
  logout: () => void;
  getMe: () => Promise<any> | void;
}

const AuthContext = createContext<IContext>({
  user: null,
  isLoading: true,
  setIsLoading: (x: boolean) => {},
  login: (email: string, password: string) => {},
  logout: () => {},
  getMe: () => {}
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<IProfile | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const logout = useCallback(() => {
    storage.removeItem('token');
    storage.removeItem('expiry');
    storage.removeItem('refresh-token');
    storage.removeItem('id-refresh');
    storage.removeItem('doctor-resources');
    storage.removeItem('doctor-tab-list');
    storage.removeItem('patient-tab-list');
    setUser(null);
    navigate('/login', { replace: true });
  }, [navigate]);

  const login = async (email: string, password: string) =>
    await api.post('/login', { email, password });

  const getMe = useCallback(async () => {
    try {
      const resp = await api.get('/me');
      if (resp.status === 200) {
        const {
          data
        }: {
          data: IProfile;
        } = resp;

        setIsLoading(false);
        storage.setItem('id-refresh', data.id);
        setUser(data);
      }
    } catch (err) {
      logout();
      setIsLoading(false);
    }
  }, [logout]);

  useEffect(() => {
    if (token) {
      getMe();
    } else {
      setIsLoading(false);
    }
  }, [getMe, token]);

  useEffect(() => {
    if (storage.getItem('token')) setToken(storage.getItem('token'));
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
        setIsLoading,
        login,
        logout,
        getMe
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
