import axiosLib, { AxiosRequestConfig, AxiosError } from 'axios';
import storage from 'utils/storage';
import { API_URL } from 'utils/config';
import { isPast } from 'date-fns';
import { ILoginResponse } from './models';
// import axios from 'axios';

export const api = axiosLib.create({
  baseURL: API_URL
});

api.interceptors.request.use(async (config: any) => {
  const token = storage.getItem('token');
  const expiry = storage.getItem('expiry');
  const refresh = storage.getItem('refresh-token');
  const userId = storage.getItem('id-refresh');

  // console.log(config.url);
  // console.log(`1:  ${expiry && new Date(+expiry)}`);

  if (expiry && isPast(new Date(+expiry))) {
    try {
      const { data } = await axiosLib.get(
        `${API_URL}/refresh?user_id=${userId}&refresh_token=${refresh}`,
        {
          headers: {
            Authorization: `Bearer ${refresh}`
          }
        }
      );

      const { data: respData } = data as {
        data: ILoginResponse;
        status: number;
      };
      const access_token = respData.access_token;

      storage.setItem('token', access_token);
      storage.setItem('expiry', JSON.stringify(respData.expiry));
      storage.setItem('refresh-token', respData.refresh_token);

      config.headers.Authorization = `Bearer ${access_token}`;
      window.location.reload();
    } catch (error: any) {
      // console.log(error);
      storage.removeItem('token');
      storage.removeItem('expiry');
      storage.removeItem('token-refresh');
      storage.removeItem('id-refresh');
      window.location.reload();
    }
  } else {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

// api.interceptors.response.use(
//   (resp) => {
//     console.log(resp);
//   },
//   (error) => console.log(error)
// );

const ApiCalls = {
  get: async (url: string, options?: AxiosRequestConfig) => {
    try {
      return await api.get(url, options);
    } catch (error: any) {
      error.request = { url, options };
      throw error;
    }
  },
  delete: async (url: string, options?: AxiosRequestConfig) => {
    try {
      return await api.delete(url, options);
    } catch (error: any) {
      error.request = { url, options };
      throw error;
    }
  },
  post: async (url: string, data: any = {}, options?: AxiosRequestConfig) => {
    try {
      return await api.post(url, data, options);
    } catch (error: any) {
      error.request = { url, data, options };
      throw error;
    }
  },
  put: async (url: string, data: any, options?: AxiosRequestConfig) => {
    try {
      return await api.put(url, data, options);
    } catch (error: any) {
      error.request = { url, data, options };
      throw error;
    }
  },
  patch: async (url: string, data: any, options?: AxiosRequestConfig) => {
    try {
      return await api.patch(url, data, options);
    } catch (error: any) {
      error.request = { url, data, options };
      throw error;
    }
  },
  handleError: (error: AxiosError, text?: string, report = true) => {
    // if (report && error.response.status) reportError(error.req, error)
    console.log(error);
  }
};

export default ApiCalls;
