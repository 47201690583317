import React, { FC } from 'react';
import { AuthProvider } from 'contexts/AuthContext';
import Router from 'Router';

const App: FC = () => (
  <AuthProvider>
    <Router />
  </AuthProvider>
);

export default App;
