import React, { FC, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { IS_DEV } from 'utils/constants';
import { CONF_ENVIRONMENT } from 'utils/config';
import { Loading } from 'hooks/useLoading';
import { IMenuTypes } from 'utils/models';
import useAuth from 'hooks/useAuth';

// components
import SideBar from 'components/SideBar';
import LoaderSpinner from 'components/LoaderSpinner';
import clsx from 'clsx';
import Header from 'components/Header';

const AuthLayout: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const { logout } = useAuth();
  const navigate = useNavigate();

  const menu: IMenuTypes[] = [
    {
      label: 'Logout',
      onClick: () => {
        logout();
        navigate('/login', { replace: true });
      }
    }
  ];

  return (
    <Loading.Provider value={{ loading, setLoading }}>
      {loading && (
        <div className="bg-black w-full z-50 h-screen fixed bg-opacity-50">
          <div className="flex justify-center items-center fixed h-full w-full">
            <LoaderSpinner className="w-10 h-20" />
          </div>
        </div>
      )}

      <main className={clsx('flex flex-col bg-gray-25', 'md:h-screen  bg-opacity-80')}>
        <div className="md:flex flex-1 md:overflow-hidden">
          <div className="flex flex-1 flex-col">
            <div
              className={clsx(
                'flex flex-col bg-polln-light-green-500 relative z-10',
                'md:shadow-md md:bg-white',
                !IS_DEV.includes(CONF_ENVIRONMENT || 'development') && 'px-3 py-0.5'
              )}
            >
              <Header menu={menu} />
            </div>

            <div className="flex flex-1 overflow-y-auto h-full">
              <div className="hidden md:block">
                <SideBar />
              </div>

              <div className="flex flex-col flex-1 overflow-y-auto">
                <div className=" px-7 md:pt-5 pb-10 md:pb-0" id="main">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Loading.Provider>
  );
};

export default AuthLayout;
